

















































import Vue from 'vue';

export default Vue.extend({
  name: 'Pregame',
  data() {
    return {
      gameMode: [],
      gameMap: [],
      player: [
        {name: "DUDE"},
        {name: "CHEVRE"},
      ],
      loader: false,
      color: 'dark',
    }
  },
  computed: {
    gameSock: {
      get() {
        return this.$store.getters.getGameSock;
      },
      set(value) {
        this.$store.commit('setGameSock', value);
      }
    },
  },
  methods:{
    readyStat() {
      this.gameSock.on('stop', () => {
        this.endDialog = true;
        this.gameSock.disconnect();
      });
      this.loader = !this.loader;
      this.color = 'green';
      this.gameSock.on('ready', (options, users) => {
        this.$store.commit('setGameOptions', options);
        this.$store.commit('setUsersInGame', users);
        this.gameSock.off('stop');
        this.$router.push({ name: 'game'});
      });
      this.gameSock.emit('ready', { plan: this.gameMap, mode: this.gameMode });
    }
  },
  created() {
    this.$toast.clear();
    this.gameSock.on('stop', () => {
      this.endDialog = true;
      this.gameSock.disconnect();
      this.$toast.warning("Other player left", {
        position: 'top-center',
        pauseOnHover: false,
      })
      this.$router.push({ name: 'Main' });
    });
  },

  beforeRouteLeave(to, from, next) {
    if (this.gameSock.connected && to.name !== 'game')
      this.gameSock.disconnect();
    next();
  },
});
